<template>
  <div class="wrapper">
    <div class="page_container">
      <BackComponent title="Inbox" />
    </div>
    <section class="inbox-section">
      <div
        v-for="(notification, index) in notifications"
        :key="index"
        class="notification-item"
      >
        <router-link :to="link" @click.native="closeMenu">
          <div class="notification-content">
            <div class="icon">
              <NotificationIcon />
            </div>
            <div class="content">
              <div>
                <p>
                  {{ notification.title }}
                </p>
                <span>
                  {{ notification.description }}
                </span>
              </div>
              <div class="time">
                <span>{{ notification.time }}</span>
                <span class="betnow special-font">{{ notification.cta }}</span>
              </div>
            </div>
          </div>
          <div
            v-if="notification.statusIcon"
            class="notification-status-icon"
          ></div>
        </router-link>
      </div>
    </section>
  </div>
</template>

<style scoped src="./index.css"></style>

<script>
import walletserve from "@/services/walletserve";
import bonusserve from "@/services/bonus";
import NotificationIcon from "../icons/NotificationIcon.vue";

export default {
  name: "Inbox",
  components: {
    BackComponent: () => import("@/components/ui/BackComponent"),
    NotificationIcon,
    // SortDeposit,
    // MenuItem,
  },
  data: function () {
    return {
      fiatCurrency: process.env.VUE_APP_CURRENCY,
      transaction_id: "",
      amount: "",
      mb8Balance: "0",
      loading: "",
      pro: {},
      bonus_code: "",
      errorDisplayed: false,
      withdraw_amount: "",
      new_referral_code: "",
      errors: [],
      code: "",
      link: "",
      copyText: "Copy",
      copyBookingCode: "Click to copy",
      // myProfile: this.getProfile(),
      depositPage: true,
      withdrawalPage: false,
      // myBonusBalance: 0,
      showSuccessModal: false,
      notifications: [
        {
          title: "Chop6 is back!",
          description: this.$t("predictScores"),
          time: "Sat 24/08 • 6:13 am",
          statusIcon: true,
          cta: "Bet now",
          link: "/gifts",
        },
        {
          title: "Welcome Bonus",
          description: this.$t("predictScores"),
          time: "Sat 24/08 • 6:13 am",
          statusIcon: null,
          cta: "Start now",
          link: "",
        },
        {
          title: "Welcome Bonus",
          description: this.$t("predictScores"),
          statusIcon: null,
          link: "",
        },
      ],
    };
  },
  watch: {
    $route: {
      immediate: true,
      handler() {
        document.title = "My Chopbet.ci Account";
        document.description = "Manage Chopbet.ci account";
      },
    },
    new_referral_code: function (n) {
      console.log("got new value here " + n);

      this.validateReferralLink();
    },
  },
  created() {
    var profile = this.getProfile();

    // Check if the user is logged in
    var isLoggedIn = profile && profile.a;

    if (isLoggedIn) {
      this.EventBus.$on("mb8:updated", (mb8balance) => {
        this.receivedMB8Balance = mb8balance;
      });
      this.EventBus.$on("bonus:updated", (bonus) => {
        this.receivedBonus = bonus;
      });
    }
  },
  mounted() {
    // this.fetchMyBonusBalance();
    this.$store.dispatch("setCurrentPage", "setting");
    // var vm = this;

    // vm.myProfile = vm.getProfile();
    // console.log("My Profile data", vm.myProfile);
    // this.reloadProfile();

    // this.getBonus();

    // if (!vm.myProfile) {
    //     this.setError("Login", this.$t("pleaseLoginProceed"));
    //     this.$router.push({ name: "login", params: {} });
    //     return;
    // }

    // setInterval(function () {
    //     vm.myProfile = vm.getProfile();
    // }, 1000);

    // this.code = vm.myProfile.referral_code;
    // this.link = vm.myProfile.referral_link;
    this.initShareInviteModal();
  },
  methods: {
    getInitials(firstName, lastName) {
      const firstInitial = firstName ? firstName.charAt(0).toUpperCase() : "";
      const lastInitial = lastName ? lastName.charAt(0).toUpperCase() : "";
      return `${firstInitial}${lastInitial}`;
    },
    fetchMyBonusBalance() {
      var vm = this;

      const path = process.env.VUE_APP_BONUS_BASE_URL + "/bonus/balance";
      console.log(this.getAuth());

      bonusserve
        .get(path, {
          headers: {
            "Content-Type": "application/json",
            "api-key": vm.getAuth(),
          },
        })
        .then((response) => {
          this.myBonusBalance = response.data.balance;
          console.log("Success bonus balance:", response.data.balance);
        })
        .catch((err) => {
          console.error("Error bonus balance:", err);
        });
    },
    showDeposit() {
      this.depositPage = true;
      this.withdrawalPage = false;
    },
    showWithdrawal() {
      this.withdrawalPage = true;
      this.depositPage = false;
    },
    // setAmount: function (amountToAdd) {
    //   // Convert the current amount to a number
    //   let currentAmount = parseFloat(this.amount);

    //   // Check if the current amount is a valid number
    //   if (isNaN(currentAmount)) {
    //     currentAmount = 0; // Set it to 0 if it's not a valid number
    //   }

    //   // Add the new amount to the existing amount
    //   currentAmount += amountToAdd;

    //   // Update the amount with the new total
    //   this.amount = currentAmount.toFixed(2); // Limit to 2 decimal places
    // },

    setAmount: function (currentAmount) {
      this.amount = parseFloat(currentAmount).toFixed(2);
    },

    generateOrRegenerateReferralCode: function () {
      this.reset();
      this.errors = [];

      var p = this.getProfile();
      var ref = p.referral_code;
      if (!p) {
        this.setError("Login", this.$t("pleaseLoginProceed"));
        this.$router.push({ name: "login", params: {} });
        return;
      }

      return ref;
    },

    redeemBonus: function () {
      var vm = this;
      var path = process.env.VUE_APP_BASE_BONUS_URL + "/campaign/redeem";

      var payload = {
        bonus_code: String(this.bonus_code),
      };

      bonusserve
        .post(path, JSON.stringify(payload), {
          headers: {
            "api-key": vm.getAuth(),
          },
        })
        .then((res) => {
          vm.loading = "";
          console.log("Response for Bonus", res);
          // var bonusdata = res.data.data;

          vm.bonusdata = res.data.data;
          7;
        })
        .catch((err) => {
          console.log("Error", err);
          vm.loading = "";
          if (err.response) {
            // console.log(JSON.stringify(err.request));
          } else {
            vm.setError(
              `${this.$t("networkError")}`,
              `${this.$t("checkYourNetwork")}`
            );
            // console.log(JSON.stringify(err));
          }
        });
    },

    deposit: function () {
      this.reset();
      var p = this.getAuth();
      if (!p) {
        this.setError("Login", this.$t("pleaseLoginProceed"));
        this.$router.push({ name: "login", params: {} });
        return;
      }

      if (this.amount < 10) {
        this.setError(
          "Invalid Amount",
          `Enter amount at least 10${this.fiatCurrency} or above`
        );
        return;
      }

      var vm = this;
      var path = "/deposit/initiate";

      var data = {
        amount: parseInt(this.amount),
      };

      vm.loading = "loading";
      console.log(this.getAuth());

      walletserve
        .post(path, JSON.stringify(data), {
          headers: {
            "api-key": vm.getAuth(),
          },
        })
        .then((res) => {
          vm.loading = "";
          var msg = res.data.data;
          console.log(msg);
          vm.setSuccess("Success", msg);
          this.showSuccessModal = true;
          setTimeout(() => {
            this.showSuccessModal = false;
          }, 2000);
        })
        .catch((err) => {
          vm.loading = "";
          if (err.response) {
            if (
              parseInt(err.response.data.status) === 401 ||
              parseInt(err.response.data.status) === 428
            ) {
              vm.setError(`${this.$t("sessionExpired")}`);
              vm.logout();
              return;
            } else {
              vm.setError("Failed", err.response.data.error_message);
              // console.log(JSON.stringify(err.response.data.message));
            }
          } else if (err.request) {
            vm.setError(
              `${this.$t("networkError")}`,
              `${this.$t("checkYourNetwork")}`
            );
            // console.log(JSON.stringify(err.request));
          } else {
            // vm.setError(
            //   `${this.$t("networkError")}`,
            //   `${this.$t("checkYourNetwork")}`
            // );
            console.log(JSON.stringify(err));
          }
        });
    },

    jisort: function () {
      this.reset();
      var p = this.getAuth();
      if (!p) {
        this.setError("Login", this.$t("pleaseLoginProceed"));
        this.$router.push({ name: "login", params: {} });
        return;
      }

      var vm = this;
      var path = "/deposit/reconcile";

      var data = {
        transaction_id: this.transaction_id,
      };

      vm.loading = "loading";
      console.log(this.getAuth());

      walletserve
        .post(path, JSON.stringify(data), {
          headers: {
            "api-key": vm.getAuth(),
          },
        })
        .then((res) => {
          vm.loading = "";
          var msg = res.data.data;
          console.log(msg);
          vm.setSuccess("Success", msg);
          this.showSuccessModal = true;
          setTimeout(() => {
            this.showSuccessModal = false;
          }, 2000);
        })
        .catch((err) => {
          vm.loading = "";
          if (err.response) {
            if (
              parseInt(err.response.data.status) === 401 ||
              parseInt(err.response.data.status) === 428
            ) {
              vm.setError(`${this.$t("sessionExpired")}`);
              vm.logout();
              return;
            } else {
              vm.setError("Failed", err.response.data.error_message);
              // console.log(JSON.stringify(err.response.data.message));
            }
          } else if (err.request) {
            vm.setError(
              `${this.$t("networkError")}`,
              `${this.$t("checkYourNetwork")}`
            );
            // console.log(JSON.stringify(err.request));
          } else {
            // vm.setError(
            //   `${this.$t("networkError")}`,
            //   `${this.$t("checkYourNetwork")}`
            // );
            console.log(JSON.stringify(err));
          }
        });
    },

    withdraw: function () {
      if (this.loading === "loading") {
        return;
      }

      var vm = this;

      setTimeout(function () {
        vm.loading = "";
      }, 5000);

      this.reset();

      var p = this.getAuth();

      if (!p) {
        this.loading = "";
        this.setError("Login", this.$t("pleaseLoginProceed"));
        this.$router.push({ name: "login", params: {} });
        return;
      }

      if (this.withdraw_amount < 50) {
        this.setError(
          "Invalid Amount",
          `Enter amount at least 50${this.fiatCurrency} or above`
        );
        return;
      }

      var path = "/withdraw";

      var data = {
        amount: parseInt(this.withdraw_amount),
        // msisdn: parseInt(this.msisdn),
      };

      this.loading = "loading";

      walletserve
        .post(path, data, {
          headers: {
            "api-key": vm.getAuth(),
          },
        })
        .then((res) => {
          console.log("Response for Withdraw", res);
          vm.loading = "";
          var msg = res.data.data;
          if (parseInt(res.data.status) === 200) {
            vm.setSuccess("Withdrawal Initiated", msg);
          }
          if (parseInt(res.data.status) === 201) {
            vm.setSuccess("Withdrawal Initiated", msg);
          }
          this.showSuccessModal = true;
          setTimeout(() => {
            this.showSuccessModal = false;
          }, 2000);
        })
        .catch((err) => {
          console.log("Error", err);
          vm.loading = "";
          var msg = err.response.data.error_message;
          if (!vm.errorDisplayed) {
            if (parseInt(err.response)) {
              vm.setError("Failed", msg);
            } else if (
              parseInt(err.response.data.status) === 401 ||
              parseInt(err.response.data.status) === 428
            ) {
              vm.setError("Session Expired", `${this.$t("sessionExpired")}`);
              vm.logout();
            } else {
              vm.setError("Failed", msg);
              // console.log(JSON.stringify(err.response.data.message));
            }
            vm.errorDisplayed = true;
          }
        });
    },

    shareInvite: function () {
      document.getElementById("open-shareinvite").click();
    },
    copyShareBetLink: function () {
      var profile = this.getProfile();
      if (!profile) {
        return;
      }
      var link = "https://dev.chopbet.ci/join?p=" + profile.referral_code;
      this.copyToClipboard(link);
      this.copyText = "Copied";
    },

    copyCode: function () {
      var link = "accept#" + this.code;
      this.copyToClipboard(link);
      this.copyBookingCode = "Invite Code Copied";
    },

    initShareInviteModal: function () {
      var modal = document.getElementById("shareinvite-modal");

      // Get the button that opens the modal
      var btn = document.getElementById("open-shareinvite");

      // Get the <span> element that closes the modal
      //var span = document.getElementsByClassName("sharebet-close")[0];

      // When the user clicks on the button, open the modal
      btn.onclick = function () {
        modal.style.display = "block";
      };

      // When the user clicks on <span> (x), close the modal
      /*
            span.onclick = function() {
              modal.style.display = "none";
            }
            */

      // When the user clicks anywhere outside of the modal, close it
      window.onclick = function (event) {
        if (event.target == modal) {
          modal.style.display = "none";
        }
      };

      document.addEventListener("click", (e) => {
        if (e.target == document.querySelector("#shareinvite-modal")) {
          modal.style.display = "none";
        }
      });
    },
  },

  computed: {
    bal: function () {
      return this.formatCurrency(this.profile.b1);
    },
    bonus: function () {
      return this.formatCurrency(
        this.profile.balance + this.profile.pending_activation
      );
    },
    myBalance: function () {
      return this.formatCurrency(this.$store.state.balance);
    },
    myBonus: function () {
      return this.$store.state.bonus;
    },
    msg: function () {
      return "Karibu Chopbet.ci, Come let's win BIG together.";
    },
    profile: function () {
      return this.myProfile;
    },
    // has_referral_code: function () {
    //   return this.code !== undefined && this.code.length > 3;
    // },
    shouldDisplayTrivia() {
      const now = new Date();
      const startDate = new Date("2023-11-22T12:00:00");
      const endDate = new Date("2023-11-22T15:30:00");

      return now >= startDate && now <= endDate;
    },
  },
  filters: {
    currency: function (amount) {
      if (amount == null) {
        amount = 0;
      }

      return amount.toLocaleString("en-US", {
        style: "currency",
        currency: "CFA",
      });
    },
    formatOdds: function (x) {
      if (x === undefined) {
        return 1;
      }

      return parseFloat(x).toFixed(2);
    },
  },
};
</script>
